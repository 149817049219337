/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    soundwave: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M8.5 2a.5.5 0 01.5.5v11a.5.5 0 01-1 0v-11a.5.5 0 01.5-.5m-2 2a.5.5 0 01.5.5v7a.5.5 0 01-1 0v-7a.5.5 0 01.5-.5m4 0a.5.5 0 01.5.5v7a.5.5 0 01-1 0v-7a.5.5 0 01.5-.5m-6 1.5A.5.5 0 015 6v4a.5.5 0 01-1 0V6a.5.5 0 01.5-.5m8 0a.5.5 0 01.5.5v4a.5.5 0 01-1 0V6a.5.5 0 01.5-.5m-10 1A.5.5 0 013 7v2a.5.5 0 01-1 0V7a.5.5 0 01.5-.5m12 0a.5.5 0 01.5.5v2a.5.5 0 01-1 0V7a.5.5 0 01.5-.5"/>',
    },
});
